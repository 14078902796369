.post-content {
    #team {
        padding: 40px 0;

        .member {
            @include clearfix;
            position: relative;
            z-index: 1;
            display: block;
            margin-bottom: 2em;
            padding: 10px;
            border: 2px solid nth($color, 10);
        }

        .thumbnail,
        .details {
            float: left;
        }

        .thumbnail {
            @include rel-height(100%);
            @include cover;
            display: block;
            width: 200px;
            height: 200px;
            margin-right: -240px;
        }

        .details {
            &.thumb {
                margin-left: 240px;  
            }
            header {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid nth($color, 10);
            }
            .name {
                margin: 0;
                font-size: 1.25em;
                font-weight: 700;
                text-transform: uppercase;
            }
            .meta {
                p {
                    font-size: em(14px);
                    margin: 0;
                    font-weight: 700;
                }
            }

            .excerpt {
                font-size: em(14px);
            }

            .extra {
                i {
                    font-size: 1.25em;
                }

                .phone,
                .email {
                    position: relative;
                    z-index: 1;
                    padding-left: 30px;
                    i {
                        position: absolute;
                        left: 0;
                    }
                }

                .social {
                    li {
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
            }

            .more {
                padding-top: 1em;
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        @include media($brDesktop) {
            .member {
                .thumbnail {
                    width: 150px;
                    height: 150px;
                    margin-right: -180px;
                }
                .details {
                    margin-left: 180px;
                }
            }
        }

        @include media($brTablet) {
            .member {
            }
        }

        @include media($brMobile) {
            .member {
                .thumbnail,
                .details {
                    float: none;
                }

                .thumbnail {
                    margin: 0 auto 20px;
                    border-radius: 50%;
                }

                .details {
                    margin-left: 0;
                }
            }
        }
    }
}