.flexible-content {
    clear: both;
    padding: 20px 0;

    &.boxes {
        .items {
            @include clearfix;
            @for $i from 1 through 12 {
                $c: 12 / $i;
                &.items-per-row-#{$i} {
                    .item {
                        @include span-columns($c);
                        @include omega(#{$i}n);
                    }
                }
            }

            .item {
                position: relative;
                z-index: 1;
                margin-bottom: 20px;
                padding: 1px;
                border: 2px solid rgba(#000, .1);
                figure {
                    @include rel-height(60%);
                    @include cover;
                    + i {
                        position: absolute;
                        top: -15px;
                        left: -15px;
                        display: block;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        background: #fff;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 42px;
                    }
                }

                .content {
                    padding: 20px;
                    font-size: rem(16);
                    .title {
                        margin-top: 0;
                    }
                    .desc {
                        margin-bottom: 20px;
                        p {
                            &:last-child {
                                margin: 0;
                            }
                        }
                    }

                }
            }
        }

        @include media($brDesktop) {
            .items {
                .item {
                    .content {
                        .title {
                            font-size: rem(16);
                        }
                    }
                }
            }
        }

        @include media($brTablet) {
            .items {
                @for $i from 3 through 12 {
                    &.items-per-row-#{$i} {
                        .item {
                            @include span-columns(6 of 12);
                        }
                    }
                }
            }
        }

        @include media($brMobile) {
            .items {
                @for $i from 3 through 12 {
                    &.items-per-row-#{$i} {
                        .item {
                            @include fill-parent;
                        }
                    }
                }
            }
        }
    }

    &.accordion {

    }

    &.tabs {

    }

    &.buttons {

    }
}