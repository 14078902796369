.post-content {
    ul.sitemap {
        padding: 0 0 0 1.5rem;
        border-left: 1px solid nth($color, 9);
        > li {
            position: relative;
            display: block;
            padding: .5em 0;
            &:before {
                position: absolute;
                left: -1.5rem;
                top: 1.25rem;
                display: block;
                content: '';
                width: 1rem;
                height: 1px;
                background: nth($color, 9);
            }
            > a {
                font-size: rem(20);
                font-weight: 700;
                font-family: $heading-font-family;
            }

            ul {
                padding: 0 1em;
                li {
                    @include bullet-list($icon: "\f105", $top: 2px, $bullet-color: nth($color, 8));
                    &:before {
                        background: none;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
}