.section.contact {
    padding: 60px 0;

    .bg {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        img {
            display: block;
            width: 100%;
        }
    }

    .wrap {
        z-index: 2;
        height: 100%;

        .content {

            .form {
                width: 50%;
                margin: 0 auto;
                padding: 40px;
                background: rgba($footerBg, .85);
                border: 5px solid $footerBg;
                color: #333;

                .description {
                    margin-bottom: 1em;
                    a {
                        color: #333;
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                div.gform_wrapper {
                    li.gfield,
                    li.gfield.gfield_error {
                        position: relative;

                        label.gfield_label {
                            display: none;
                        }

                        #{$all-text-inputs} {
                            @include transition(opacity .4s);
                            color: nth($color, 7);
                            opacity: .5;
                            &:hover {
                                opacity: .75;
                            }
                            &:focus {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .gform_confirmation_wrapper {
                text-align: center;
                color: $mainBg;
                font-size: 25px;
                background: $footerBg;
                padding: 40px;
            }
        }
    }

    @include media($brDesktop) {
        .wrap {
            .content {
                .form {
                    width: 80%;
                }
            }
        }

    }

    @include media($brTablet) {
        height: auto;
        padding: 40px 0;

        .bg {
            @include cover;
            bottom: 0;
            img {
                display: none;
            }
        }

        .wrap {
            .content {
                @include transform(none);
                position: relative;
                top: auto;
                left: auto;

                .form {
                    width: auto;
                    div.gform_wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }

}