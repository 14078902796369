.the-content {
    .sidebar {
        position: relative;
        z-index: 1;
        padding: 80px 40px;
        background: #fff;

        .bg {
            border-left: 1px solid nth($color, 10);
        }

        .content {
            position: relative;
            z-index: 2;
        }

        .boxes {
            .heading {
                @include heading-underline($align: center, $color: nth($color, 1));
                margin-bottom: 40px;
                text-align: center;
                font-size: 13px;
                letter-spacing: .25em;

                &:after {
                    top: 2em;
                    height: 3px;
                }
            }

            .box {
                @include rel-height(65%);
                @include cover;
                position: relative;
                z-index: 1;
                margin-bottom: 20px;
                border-top: 3px solid nth($color, 1);

                &:last-child {
                    margin-bottom: 0;
                }

                &.linked {
                    &:hover {
                        .grad {
                            background: rgba(#303030, .75);
                        }
                    }
                }

                a {
                    position: absolute;
                    display: block;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .title {
                    position: absolute;
                    z-index: 2;
                    bottom: 30px;
                    left: 30px;
                    right: 30px;
                    margin: 0;
                    padding-left: 20px;
                    font-size: 20px;
                    border-left: 3px solid nth($color, 1);
                    color: #ffffff;
                }

                .grad {
                    @include transition(background .5s);
                    @include linear-gradient(rgba(#000, 0), rgba(#000, .5));
                }
            }
        }

        .widget {
            margin-bottom: 40px;

            &#side-nav {
                .root-page {
                    font-size: 18px;
                    text-transform: uppercase;
                    a {
                        color: nth($color, 7);
                    }
                }

            }

            &#side-nav,
            &.widget_categories {
                ul {
                    li.current-menu-item,
                    li.current-cat {
                        &:before {
                            background-color: nth($color, 2);
                        }

                        a {
                            color: $mainBg;
                            font-weight: 700;
                            background-color: nth($color, 1);
                        }
                    }
                    > li {
                        margin-bottom: 5px;
                        border: none;

                        a {
                            @include transition(background .4s);
                            background: nth($color, 10);
                            &:hover {
                                background: nth($color, 9);
                            }
                        }
                    }
                }
            }

            &.widget_search {

                .screen-reader-text {
                    display: none;
                }
                form {
                    position: relative;
                    input {
                        display: block;
                        &[type=text] {
                            position: relative;
                            z-index: 1;
                            width: 100%;
                            padding-right: 90px;
                            background: nth($color, 10);
                            border-bottom-color: nth($color, 9);
                            &:focus {
                                border-bottom-color: nth($color, 1);
                            }
                        }
                        &[type=submit] {
                            position: absolute;
                            z-index: 2;
                            right: 0;
                            bottom: 0;
                            padding: 0 10px;
                            background-color: transparent;
                            color: nth($color, 1);
                            &:hover,
                            &:active {
                                color: nth($color, 7);
                            }
                        }
                    }
                }

            }

            .content {
            }

            header {
                padding: 0 0 20px;

                h2, h3 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 1;
                    text-transform: uppercase;
                    a {
                        color: nth($color, 7);
                        &:hover {
                            color: nth($color, 1);
                        }
                    }
                }
            }

            p {
                margin: 0;
            }

            ul {
                li {
                    position: relative;
                    border-top: 1px solid nth($color, 10);

                    &:hover,
                    &.current-menu-item,
                    &.current-cat {
                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            z-index: 2;
                            left: -3px;
                            top: 0;
                            bottom: 0;
                            width: 3px;
                            background: nth($color, 1);
                        }
                    }

                    a {
                        display: block;
                        padding: 8px 20px;
                        color: nth($color, 7);
                    }
                }
            }

            .gform_wrapper {
                .gform_footer {
                    text-align: center;
                }
            }
        }

        .project-block {
            margin-bottom: 30px;
            h4 {
                @extend h3;
                color: nth($color, 1) !important;
                text-transform: uppercase;
            }

            .manager {
                p {
                    margin: 0;
                }

                a {
                    color: nth($color, 1);
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }

                .name {
                    font-weight: 700;
                }
            }
        }

        #map {
            position: relative;
            z-index: 1;
            iframe {
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
            }
        }

        @include media($brDesktop) {
            #contact-box {
            }
            #side-nav {
            }
        }

        @include media($brTablet) {
            padding: 40px;
            border-top: 1px solid nth($color, 10);
            margin-left: -$margin !important;
            margin-right: -$margin !important;
            @include media($brTablet) {
                margin-left: -$marginMobile;
                margin-right: -$marginMobile;
            }

            .bg {
                display: none;
            }

            #side-nav {

                ul {
                    li {
                        &.current-menu-item {
                        }
                    }
                }
            }

            .advertisement {
                img {
                    margin: 0 auto;
                }
            }
        }
    }
}
