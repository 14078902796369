body.home {
    .section {
        &.s1 {
            $height: 790px;
            position: relative;
            z-index: 1;

            .video-player {
                position: relative;
                @include clearfix;
                // margin-top: 122px; // .the-header height
                width: 100%;
                height: $height;
                overflow: hidden;
                background: #000;
                text-align: center;
                >iframe {
                    width: 100% !important;
                    height: $height !important;
                }
                &:after {
                    content: " ";
                    position: absolute;
                    z-index: 4;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.5);
                }
                .hero-video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    display: block;
                    line-height: 0;
                    height: auto;
                    min-width: 100%;
                    //max-height: $height;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
                .hero-video-content {
                    width: 75%;
                    @include align-center-vh(5);

                    p {
                        &.hero-title {
                            margin-bottom: .4em;
                            line-height: 1.1;
                            font-family: $heading-font-family;
                            font-size: 60px;
                            font-weight: 900;
                            text-transform: uppercase;
                            color: #fff;
                            text-shadow: 0 0 10px #888;
                        }
                        margin-bottom: 1em;
                        font-size: 1.4em;
                        line-height: 1.1;
                        color: #fff;
                        font-weight: normal;
                        text-shadow: 0 0 10px #888;
                        &.cta {
                            text-align: center;
                            .btn {
                                @include transition(all .5s);
                                background: rgba(81,82,86,.6);
                                &:hover {
                                    background: rgba(81,82,86,1);
                                }
                            }
                        }
                    }
                }
            }

            .slideshow {
                @include clearfix;

                .slide {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    height: $height;
                }

                .slide-image {
                    @include cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    .shade {
                        @include linear-gradient(rgba(#000, 0.2), rgba(#000, 0.5), $fallback: transparent);
                    }
                }

                &.fullscreen {
                    .content {
                        @include align-center-v(3);
                        bottom: auto;
                    }
                }

            }

            .content {
                position: absolute;
                z-index: 3;
                bottom: 90px;
                left: 0;
                right: 0;
                text-align: center;
                color: #ffffff;

                .headline {
                    font-family: $heading-font-family;
                    font-size: 60px;
                    font-weight: 900;
                    text-transform: uppercase;
                    color: #fff;
                }

                .desc {
                    margin-bottom: 2em;
                    font-size: 17px;
                }
                .more {
                    .btn {
                    }
                }
            }

            .anim {
                @include transition(opacity .5s);
                opacity: 0;
                &.ready {
                    opacity: 1;
                }
            }

            .wrap {
                height: 100%;

                &.form {
                    position: absolute;
                    z-index: 10;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: auto;
                    padding: rem(20) 0;

                    .gform_wrapper {
                        position: relative;
                        .gform_body {
                            margin-right: rem(60);
                            .gform_fields {
                                @include display(flex);
                                @include justify-content(space-between);
                                margin: 0 rem(-10);

                                li.gfield {
                                    @include flex(1 1 auto);
                                    padding: 0 rem(10);

                                    &.gfield_error {
                                        #{$all-text-inputs} {
                                            color: #ffffff;
                                            background: #ff4a35;
                                        }
                                    }

                                    label.gfield_label {
                                        display: none;
                                    }
                                    textarea {
                                        height: rem(45);
                                        width: 100%;
                                        padding-top: 0;
                                        padding-bottom: 0;
                                        line-height: rem(45);
                                        overflow: hidden;
                                    }

                                    #{$all-text-inputs} {
                                        @include placeholder {
                                            color: rgba($mainBg, .7); 
                                        }

                                        display: block;
                                        width: 100%;
                                        color: $mainBg;
                                        background: rgba($mainBg, .2);
                                        border-width: 1px;

                                        &:focus {
                                            color: nth($color, 7);
                                            border-color: $mainBg;
                                            background: rgba($mainBg, .8);
                                            @include placeholder {
                                                color: rgba(nth($color, 7), .5);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .gform_footer {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            padding: 0;

                            img {
                                @include align-center-vh(2);
                            }

                            &:after {
                                @include align-center-vh(2);
                                content: '\f1d8';
                                font-family: "FontAwesome", sans-serif;
                                display: inline-block;
                                color: $mainBg;
                                pointer-events: none;
                            }
                            input[type=submit] {
                                text-indent: -999em;
                            }
                        }
                    }
                }
            }

            @include media($brLargeDesktop) {
                .content {
                }
            }

            @include media($brDesktop) {

            }

            @include media($brTablet) {
                $height: 480px;

                .slideshow {
                    &.fullscreen {
                        .slide {
                            height: $height !important;
                        }
                    }
                    .slide {
                        height: $height;
                    }
                }

                .content {
                    left: 0;
                    right: 0;
                    bottom: 40px;
                    .headline {
                        font-size: 30px;
                    }
                    .desc {
                        font-size: 14px;
                    }
                }

                .wrap {
                    &.form {
                        position: relative;
                        bottom: auto;
                        left: auto;
                        right: auto;
                        margin: 0;
                        padding: rem(20) $margin;
                        background: nth($color, 10);
                        .gform_wrapper {
                            .gform_body {
                                margin-right: 0;
                                .gform_fields {
                                    display: block;
                                    margin: 0;
                                    li.gfield,
                                    li.gfield.gfield_error {
                                        padding: 10px 0 0 0;
                                        textarea {
                                            height: auto;
                                            overflow: visible;
                                            line-height: normal;
                                        }

                                        #{$all-text-inputs} {
                                            @include placeholder {
                                                color: nth($color, 8);
                                            }
                                            color: nth($color, 7);
                                            border-color: nth($color, 9);
                                        }
                                    }
                                }
                            }

                            .gform_footer {
                                position: relative;
                                left: auto;
                                right: auto;
                                bottom: auto;
                                padding-top: 10px;
                                text-align: center;

                                &:after {
                                    display: none;
                                }
                                input[type=submit] {
                                    text-indent: 0;
                                }
                            }
                        }
                    }
                }

            }

            @include media($brMobile) {
                .slideshow {
                    &.fullscreen {
                        .content {
                            @include transform(none);
                            top: auto;
                            bottom: 40px;
                        }
                    }
                }
                .content {
                    .headline {
                        font-size: 25px;
                    }
                    .more {
                        .btn {
                            display: block;
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }

        &.s2 {
            padding: 40px 0 0;
            text-align: center;
            color: $mainBg;
            background: nth($color, 1);
            &.no-btn {
                padding-bottom: 40px;
                .heading {
                    margin: 0;
                }
            }

            .heading {

            }

            .btn {
                position: relative;
                z-index: 2;
                top: 22px;
                border: 2px solid $mainBg;
            }

            @include media($brMobile) {
                padding: 20px 0;
                .heading {
                    margin: 0;
                    font-size: 25px;
                }
                .btn {
                    top: 40px;
                }
            }
        }

        &.s3 {
            position: relative;
            z-index: 1;
            padding: 90px 0;

            .items {
                @include display(flex);
                position: relative;
                z-index: 1;
                margin-left: -20px;
                margin-right: -20px;

                .item {
                    
                    @include transition(opacity .8s);
                    position: relative;
                    z-index: 2;
                    margin: 0 20px;
                    text-align: center;
                    opacity: 0;
                    width: 100%;
                    @include media($brTablet) {
                        width: auto;
                    }
                    .front,
                    .back {
                        @include transition(transform .4s);
                        padding: 40px 20px 20px 20px;
                        border-radius: 10px;

                        @include display(flex);
                        @include flex-direction(column);
                        @include justify-content(center);

                        .desc {
                            font-size: 14px;
                            line-height: 1.5em;
                        }
                    }

                    .front {
                        opacity: 1;
                        position: absolute;
                        top: 0; 
                        left: 0;
                        bottom: 0;
                        right: 0;
                        @include media($brTablet) {
                            position: relative;
                        }
                        z-index: 1;
                        height: 100%;
                        color: nth($color, 7);
                        background: nth($color, 10);
                        .icon {
                            position: relative;
                            margin: 0 auto 20px;
                            width: 80px;
                            height: 80px;
                            background: nth($color, 7);
                            border-radius: 50%;
                            @include media($brTablet) {
                                display: none;
                            }

                            i {
                                @include align-center-vh(2);
                                font-size: 32px;
                                color: nth($color, 10);
                            }
                        }
                        .title {
                            font-weight: 400;
                            font-size: 1.5em;
                        }
                        .more {
                            display: none;
                        }
                    }

                    .back {
                        @include transform(rotateY(90deg));
                        opacity: 0;
                        position: relative;
                        z-index: 2;
                        height: 100%; 
                        color: white;
                        background: nth($color, 1);
                        @include media($brTablet) {
                            display: none;
                        }
                        @include media($brMobile) {
                            display: block;
                        }
                        .subtitle {
                            font-weight: 400;
                            text-transform: uppercase;
                        }
                        .desc {
                            margin-bottom: 1em;
                        }
                    }

                    &.ready {
                        opacity: 1;
                    }

                    &:hover {
                        @include media($brDesktopMin) {
                            .front {
                                @include transform(rotateY(90deg));
                                z-index: 1;
                            }
                            .back {
                                @include transform(rotateY(0deg));
                                z-index: 2;
                                opacity: 1;
                            }
                        }
                    }
                    @include media($brMobile) {
                        .front {
                            display: none;
                        }
                        .back {
                            @include transform(rotateX(0deg));
                            opacity: 1;
                        }
                    }

                }
            }
            @include media($brDesktop) {
                .items {
                    margin: 0 -10px;
                    .item {
                        margin: 0 10px;
                        .front,
                        .back {
                            padding: 20px;
                        }

                        .front {
                            .title {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }

            @include media($brTablet) {
                .items {
                    @include display(block);
                    .item {
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }

                        .front {
                            .more {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &.s4 {
            height: 590px;

            .deco {
                display: block;
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                height: 100px;
                fill: $mainBg;

                &.top {
                    top: -1px;
                }

                &.bottom {
                    bottom: -1px;
                }
            }

            .bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
                -webkit-background-size: 0;
                background-size: 0;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .tint {
                z-index: 2;
                background: rgba(nth($color, 1), .4);
            }

            .wrap {
                z-index: 3;
                height: 100%;
                .items {
                    @include align-center-v(3);
                    @include display(flex);
                    @include justify-content(space-around);
                    left: 0;
                    right: 0;

                    .item {
                        text-align: center;
                        color: $mainBg;

                        .icon {
                            i {
                                font-size: 58px;
                            }
                        }
                        .counter {
                            font-size: 60px;
                        }
                        .title {
                            font-size: 20px;
                        }
                    }
                }
            }

            @include media($brTablet) {

                .bg {
                    @include cover;
                    bottom: 0;

                    img {
                        display: none;
                    }
                }

                .deco {
                    height: 50px;
                }
                .wrap {
                    .items {
                        @include display(block);
                        @include clearfix;
                        .item {
                            @include span-columns(6 of 12);
                            @include omega(2n);
                            margin-bottom: 30px;

                            .icon {
                                i {
                                    font-size: 40px;
                                }
                            }
                            .counter {
                                font-size: 40px;
                            }
                            .title {
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }

        &.s5 {
            padding: 60px 0;

            .items {
                @include display(flex);
                @include justify-content(space-around);
                margin: 0 -20px;

                .item {
                    @include flex(1 0 20%);
                    @include rel-height(100%);
                    position: relative;
                    z-index: 1;
                    margin: 0 20px;
                    width: 100%;

                    &:hover {
                        .hover {
                            @include transform(rotateX(0deg));
                            opacity: 1;
                        }
                    }

                    .bg {
                        @include cover;
                    }

                    .hover {
                        @include transform(rotateX(90deg));
                        @include transition(opacity .4s, transform .4s);
                        padding: 40px;
                        text-align: center;
                        color: $mainBg;
                        background: nth($color, 1);
                        opacity: 0;

                        a {
                            color: $mainBg;
                        }

                        .icon {
                            position: relative;
                            z-index: 2;
                            width: 50px;
                            height: 50px;
                            margin: 0 auto 20px;
                            background: $mainBg;
                            border-radius: 50%;
                            i {
                                @include align-center-vh(2);
                                font-size: 28px;
                                color: nth($color, 1);
                            }
                        }

                        .title {
                            font-weight: 400;
                        }

                        .content {
                            @include align-center-v(2);
                            left: 40px;
                            right: 40px;
                        }
                    }
                }
            }

            .section-footer {
                padding-top: 60px;
            }

            @include media($brTablet) {
                .items {
                    @include display(block);
                    margin: 0;

                    .item {
                        @include rel-height(50%);
                        margin: 0 0 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .hover {
                            @include transform(none);
                            opacity: 1;
                            top: auto;
                            padding: 20px;

                            .icon {
                                display: none;
                            }

                            .title {
                                margin: 0;
                            }
                            .content {
                                @include transform(none);
                                position: relative;
                                top: 0;
                                left: auto;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }

        &.s6 {
            @keyframes hover-anim {
                0% {
                    @include transform(scale(1));
                    opacity: 0;
                }
                50% {
                    @include transform(scale(1.2));
                    opacity: 1;
                }
                95% {
                    opacity: 0;
                }
                100% {
                    @include transform(scale(1));
                }

            }
            padding: 60px 0;
            background: nth($color, 10);

            .items {
                @include display(flex);
                @include justify-content(space-between);

                .item {
                    margin: 0 20px;
                    text-align: center;
                    &:hover {
                        .icon {
                            @include transform(scale(.9));
                            background: nth($color, 1) !important;
                            .hover {
                                @include animation-duration(2s);
                                @include animation-name(hover-anim);
                            }
                        }

                    }
                    .icon {
                        @include transition(background .5s, transform .3s);
                        @include transform(scale(1));
                        position: relative;
                        z-index: 1;
                        width: 100px;
                        height: 100px;
                        margin: 0 auto 20px;
                        border-radius: 50%;
                        background-color: nth($color, 1);

                        .hover {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            box-shadow: 0 0 20px nth($color, 1);
                            opacity: 0;
                        }

                        i {
                            @include align-center-vh(2);
                            font-size: 60px;
                            color: $mainBg;
                        }
                    }

                    a {
                        color: nth($color, 7);
                    }
                }
            }

            @include media($brTablet) {
                .items {
                    @include display(block);
                    .item {
                        margin-bottom: 30px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &.s7 {
            height: 650px;

            .deco {
                display: block;
                position: absolute;
                z-index: 2;
                left: 0;
                right: 0;
                height: 100px;
                fill: nth($color, 10);

                &.top {
                    top: -1px;

                }

                &.bottom {
                    bottom: -1px;
                }
            }

            .bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
                -webkit-background-size: 0;
                background-size: 0;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .tint {
                z-index: 2;
                background: rgba(#000, .4);
            }

            .wrap {
                z-index: 3;
                height: 100%;
                .content {
                    @include align-center-v(3);
                    left: 0;
                    right: 0;
                }

                .section-header {
                    margin-bottom: 30px;
                    .title {
                        color: $mainBg;
                    }
                }

                .items {
                    .item {
                        color: $mainBg;
                        text-align: center;

                        button.btn {
                            
                            margin: 10px;
                            a {
                                color: white;
                            }
                        }

                        figure {
                            margin-bottom: 20px;
                            img {
                                display: block;
                                margin: 0 auto;
                                width: auto;
                                border-radius: 50%;
                            }
                        }

                        .quote {
                            margin-bottom: 1.5em;
                            font-style: italic;
                        }

                        .client {
                            font-family: $heading-font-family;
                            font-weight: 700;
                            div {
                                display: inline-block;
                                text-transform: uppercase;
                            }
                        }
                    }
                }

                .owl-dots {
                    padding-top: 1em;
                }
            }

            @include media($brTablet) {
                .deco {
                    height: 50px;
                }

                .bg {
                    @include cover;
                    bottom: 0;

                    img {
                        display: none;
                    }
                }
            }
        }

        &.s8 {
            padding: 60px 0;
            background: nth($color, 10);

            .items {
                @include display(flex);
                @include justify-content(space-between);
                margin: 0 -20px;

                .item {
                    @include flex(0 1 33.33%);
                    @include transition(opacity .8s);
                    position: relative;
                    margin: 0 20px;
                    z-index: 1;
                    background: $mainBg;
                    opacity: 0;

                    &.ready {
                        opacity: 1;
                    }

                    &:hover {
                        figure {
                            .hover {
                                @include transform(rotateX(0deg));
                                opacity: 1;
                            }
                        }
                    }

                    figure {
                        @include rel-height(100%);
                        &:before {
                            content: '';
                            display: block;
                            padding-top: 60%;
                        }
                        .bg {
                            @include cover;
                        }

                        .hover {
                            @include transform(rotateX(90deg));
                            @include transition(opacity .4s, transform .4s);
                            padding: 40px;
                            text-align: center;
                            color: $mainBg;
                            background: nth($color, 1);
                            opacity: 0;

                            a {
                                color: $mainBg;
                            }

                            .title {
                                font-weight: 400;
                            }

                            .categories {
                                margin-bottom: 20px;
                                a {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }

                            .content {
                                @include align-center-v(2);
                                left: 40px;
                                right: 40px;
                            }
                        }
                    }

                    .body {
                        padding: 40px;
                        .title {
                            margin: 0;
                            font-size: 24px;
                        }
                        .date {
                            display: block;
                            margin-bottom: 20px;
                            font-size: 14px;
                        }
                        .excerpt {
                            font-size: 14px;
                        }
                    }

                }
            }

            .section-footer {
                padding-top: 60px;
                .more {
                    &:before,
                    &:after {
                        background: nth($color, 9);
                    }
                }
            }

            @include media($brDesktop) {
                .items {
                    margin: 0 -10px;
                    .item {
                        margin: 0 10px;
                        figure {
                            @include rel-height(100%);
                            .hover {
                                font-size: .75em;
                                .btn {
                                    font-size: 12px;
                                }
                            }
                        }
                        .body {
                            padding: 20px;
                            .title {
                                font-size: 18px;
                            }
                            .date {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            @include media($brTablet) {
                .items {
                    @include display(block);
                    margin: 0;

                    .item {
                        margin: 0 0 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }

                        figure {
                            @include rel-height(50%);
                        }
                    }
                }
            }

        }

        &.s9 {
            padding: 90px 0;
            background: #ffffff;

            .items {
                margin: 0 50px;

                .item {
                    .pad {
                        padding: 0 20px;
                        text-align: center;
                        img {
                            width: auto;
                            margin: 0 auto;
                        }
                    }
                }
                .carousel {
                    @include clearfix;
                    > .item {
                        width: percentage(1 / 6);
                        float: left;
                    }
                }
                .owl-carousel {
                    .owl-nav {
                        .owl-prev,
                        .owl-next {
                            position: absolute;
                            z-index: 5;
                            top: 0;
                            bottom: 0;
                            width: 50px;
                            background: transparent;
                            &:hover,
                            &:active {
                                i {
                                    color: nth($color, 1);
                                }
                            }
                            i {
                                @include align-center-vh(1);
                                font-size: 42px;
                            }
                        }

                        .owl-prev {
                            left: -50px;
                        }
                        .owl-next {
                            right: -50px;
                        }
                    }
                }
            }

            @include media($brTablet) {
                padding: 40px 0;

                .items {
                    margin: 0;
                    .owl-carousel {
                        .owl-nav {
                            .owl-prev,
                            .owl-next {
                                width: $margin;
                            }

                            .owl-prev {
                                left: -$margin;
                            }
                            .owl-next {
                                right: -$margin;
                            }
                        }
                    }
                }

                .section-header {
                    margin-bottom: 0;
                    ul.tabs {
                        li {
                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .posts {
                    .tab-content {
                        article {
                            @include fill-parent;
                            margin-bottom: 20px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        &.s10 {
            height: 400px;

            .bg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                z-index: 1;
                -webkit-background-size: 0;
                background-size: 0;

                img {
                    display: block;
                    width: 100%;
                }
            }

            .tint {
                z-index: 2;
                background: rgba(#000, .6);
            }

            .wrap {
                z-index: 3;
                height: 100%;
                .content {
                    @include align-center-v(3);
                    left: 0;
                    right: 0;
                    color: $mainBg;

                    .cols {
                        @include clearfix;
                        .col {
                            @include span-columns(6);
                        }
                    }

                    .section-header {
                        margin-bottom: 30px;
                        .title {
                            text-align: left;
                            &:after {
                                @include transform(none);
                                left: 0;
                            }
                        }

                        .desc {
                            text-align: left;
                        }
                    }
                }
            }

            @include media($brTablet) {
                .bg {
                    @include cover;
                    bottom: 0;

                    img {
                        display: none;
                    }
                }

                .wrap {
                    .content {
                        .cols {
                            .col {
                                @include fill-parent;
                            }
                        }
                    }
                }
            }
        }

        .section-header {
            margin-bottom: 90px;
            .title {
                @include heading-underline($align: center, $color: nth($color, 1));
                margin: 0 0 40px;
                font-size: 32px;
                font-weight: 400;
                text-align: center;
                &:after {
                    top: auto;
                    width: 150px;
                    height: 2px;
                }
            }

            .desc {
                text-align: center;
            }

            @include media($brTablet) {
                margin-bottom: 40px;
                .title {
                    font-size: 24px;
                }
            }
        }

        .section-footer {
            .more {
                @include display(flex);
                @include justify-content(space-between);
                @include align-items(center);

                text-align: center;
                text-transform: uppercase;
                &:before,
                &:after {
                    @include flex(1 1 auto);
                    display: block;
                    content: "";
                    height: 2px;
                    background: nth($color, 10);
                }

                &:before,
                &:after,
                a {
                }

                a {
                    display: block;
                    margin: 0 40px;
                    font-family: $heading-font-family;
                    color: nth($color, 7);
                    &:hover {
                        color: nth($color, 1);
                    }
                }
            }
        }
    }
}