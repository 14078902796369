#hero-image {
    position: relative;
    z-index: 1;
    height: 380px;

    &.is-404 {
        //height: 689px;
        background: #faf9f0;
        header {
            .title,
            .subtitle {
                color: nth($color, 7);
            }
        }
        .shade {
            &.tint {
                display: none;
            }
            &.bg {
                background: no-repeat center top;
            }
        }
    }

    .wrap {
        height: 100%;
    }

    .shade {
        &.bg {
            @include cover;
        }
        &.tint {
            @include linear-gradient(rgba(#000, 0), rgba(#000, .5));
        }
    }

    header {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 40px;

        #breadcrumbs {
            position: absolute;
            z-index: 3;
            left: 0;
            bottom: 170px;
            opacity: .75;
            a {
                color: $mainBg;
            }
        }

        .title,
        .subtitle {
            color: $mainBg;
        }

        .title {
            position: relative;
            margin: 0;
            line-height: 1.1;
            font-size: 60px;
            text-transform: uppercase;
            font-weight: 900;
            i {
                color: nth($color, 1);
                vertical-align: middle;
            }
            span {
                display: inline-block;
            }
        }

        .subtitle {
            font-size: 1.25em;
            font-weight: 400;
        }
    }

    @include media($brLargeDesktop) {
        header {
        }
    }

    @include media($brDesktop) {
        &.is-404 {
            height: 380px;
            .shade {
                &.bg {
                    @include cover;
                    background-position: right top;
                }
            }
        }
        header {
        }
    }
    @include media($brTablet) {
        height: 300px;

        header {
            bottom: 20px;

            #breadcrumbs {
                bottom: 150px;
            }
            .title {
                font-size: 28px;
            }
        }
    }

    @include media($brMobile) {
    }
}

body.single-post {
    #hero-image {
        header {
            .title {
                font-size: 40px;
                text-transform: none;
            }
        }
    }
}