.flexible-content {
    ul.tabs {
        > li {
            > a.tab {
                display: block;
                padding: 0 1em;
                font-size: .88em;
                line-height: 35px;
                background: nth($color, 10);
                color: nth($color, 7);
                text-transform: uppercase;
                
                &:hover {
                    text-decoration: none;
                    color: #ffffff;
                    background: nth($color, 4);
                }
                &.active {
                    font-weight: 700 !important;
                    background-color: nth($color, 9) !important;
                    color: nth($color, 7) !important;
                }
            }
            .tab-content {
                padding: 1rem 0 0;
                border-bottom: 1px dashed nth($color, 9);
            }
        }
    }
    @media screen and (min-width: 1024px){
        ul.tabs {
            @include clearfix;
            
            > li {
                display: inline;
                
                > a.tab {
                    display: inline-block;
                    background-color: transparent;
                }
                .tab-content {
                    float: left;
                    padding: 1.5rem 1rem 0.5rem;
                    border-top: 1px solid nth($color, 9);
                }
            }
        }
    }
}