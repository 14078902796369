body.page-template-landing {
    font-size: rem(20);

    .main {
        color: $mainBg;
        h1, h2, h3, h4, h5, h6 {
            color: $mainBg;
        }

        .cols {
            @include clearfix;
            .col {
                @include span-columns(6);

                &.form-has-bg {
                    .form {
                        padding: rem(30);
                        box-shadow: 0 5px 30px rgba(#000, .2);
                    }
                }
            }
        }

        .form {
            .title {
                margin: 0;
                font-weight: 400;
            }
            .description {
                font-size: rem(16);
            }
        }

    }

    .the-content {
        @include cover;
    }

    .extra-content {
        padding: rem(40) 0;
        font-size: $em-base;
        border-top: 1px solid $mainBg;
        background: rgba($mainBg, .8);

    }

    @include media($brTablet) {
        .main {
            .cols {
                .col {
                    @include fill-parent;
                }
            }
        }
    }
}