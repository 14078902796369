/* FOOTER
================================================================================================ */
.the-footer {
    position: relative;
    z-index: 3;
    font-size: 14px;
    color: nth($color, 8);


    a {
        &:hover {
            color: nth($color, 1);
        }
    }

    .top {
        padding: 60px 0;
        background: lighten($footerBg, 2.5%);


        .cols {
            @include display(flex);
            @include justify-content(space-between);
            .col {
                @include flex(0 1 25%);
                margin-right: 40px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .logo {
            img {
                display: block;
                margin-bottom: 30px;
            }
            + .desc {
                margin-bottom: 20px;
            }
        }

        .map {
            border: 1px solid nth($color, 8);

            iframe {
                display: block;
                width: 100%;
            }
        }

        h4 {
            text-transform: uppercase;
            color: nth($color, 1);
            font-weight: 900;
        }


        nav {
            > ul {
                > li {
                    padding: 10px 0;
                    border-bottom: 1px solid nth($color, 7);

                    a {
                        display: block;
                        line-height: 1;
                        color: nth($color, 8);
                        &:hover {
                            color: $mainBg;
                        }
                    }

                    ul {
                        padding: 5px 0 0 10px;
                        li {
                            padding: 5px 0;
                        }
                    }
                }
            }
        }

        .location {

            address {
                margin-bottom: 1em;
            }
            .phone {
                a {
                    font-weight: 700;
                    &:hover {
                        color: $mainBg;
                    }
                }
            }
        }


    }


    .bottom {
        padding: 20px 0;
        font-size: 12px;


        .cols {
            @include clearfix;
            .col {
                text-align: center;
            }
        }


        .copyright {
            color: nth($color, 8);
            a {
                color: nth($color, 8);
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            p {
            }
        }

        .social-networks {
            a {
                margin: 0 0 0 15px;
                &:hover {
                    i {
                        opacity: 1;
                    }
                }
                i {
                    opacity: .75;
                }
            }
        }


    }

    @include media($brDesktop) {
        .top {
            .cols {
                @include flex-wrap(wrap);
                .col {
                    @include flex-grow(1);
                    margin-bottom: 20px;
                }
            }
        }
    }
    @include media($brTablet) {

        .top {
            padding: 50px 0;
            text-align: center;

            .cols {
                @include display(block);
                .col {
                    margin: 0 0 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .logo {
                img {
                    margin: 0 auto 20px;
                }
            }
            nav {
                ul {
                    li {
                        float: none;
                        margin: 0;
                        a {
                            padding: 5px 0;
                        }
                    }
                }
            }


        }


        .bottom {
            .cols {
                .col {
                    float: none;
                    width: auto;
                    text-align: center;
                    &.c1 {
                        margin-bottom: 20px;
                    }
                }
            }
            .social-networks {
                text-align: center;
                a {
                    margin: 0 10px;
                }

            }
        }
    }


    @include media($brMobile) {


    }
}