@import "breadcrumbs";
@import "contact-form";
@import "dialogs";
@import "forms";
@import "hero";
@import "mobile-nav";

.shade {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

figure,
.gallery {
    .shadow {
        @include linear-gradient(rgba(#000, 0), rgba(#000, .5));
        @include transform(skewX(-3deg));
        @include filter(blur(3px));
        position: absolute;
        z-index: 1;
        width: 40px;
        top: 0;
        bottom: 5px;
        right: -5px;
    }
}

.scroll-container {
    overflow: hidden;
    position: relative;
}

.pagination {
    ul {
        font-size: em(15px);
        text-align: center;
        li {
            display: inline-block;

            &.active {
                a {
                    font-weight: 700;
                    color: #ffffff;
                    background: nth($color, 4);
                }
            }
            a {
                display: block;
                padding: 0 5px;
                background: nth($color, 10);
                &:hover {
                    color: #ffffff;
                    background: nth($color, 1);
                    text-decoration: none;
                }
            }

            i {
                font-size: em(12em);
            }
        }
    }
}

.tab-content {
    display: none;
    padding-top: 1em;
    &.active {
        display: block;
    }
}

.ui-tooltip {
    position: absolute;
    z-index: 9999;
    .ui-tooltip-content {
        max-width: 200px;
        padding: 10px;
        font-size: .66em;
        background: $mainBg;
        border-radius: $base-border-radius;
        box-shadow: 0 0 5px rgba(nth($color, 1), .25);
    }
}

.social-networks {
    white-space: nowrap;

    a {
        display: inline-block;
        margin-left: 5px;
        text-align: center;
        &:first-child {
            margin-left: 0;
        }

        i {
            font-size: 24px;
            color: #fff;
        }

        &:hover {
            i {
                opacity: .5;
            }
        }

    }
}



.owl-carousel {
    .owl-dots {
        text-align: center;
        .owl-dot {
            display: inline-block;
            margin: 0 5px;
            &.active {
                span {
                    background: #ffffff;
                }
            }
            span {
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }
    }

    .owl-nav {
        .owl-prev,
        .owl-next {
            position: absolute;
            z-index: 5;
            top: 0;
            bottom: 0;
            width: 50px;
            background: transparent;

            &:hover,
            &:active {
                i {
                    color: nth($color, 1);
                }
            }
            i {
                @include align-center-vh(1);
                font-size: 42px;
            }
        }

        .owl-prev {
            left: -50px;
        }
        .owl-next {
            right: -50px;
        }
    }

    @include media($brLargeDesktop) {
        .owl-nav {
            .owl-prev,
            .owl-next {
                width: 40px;
            }

            .owl-prev {
                left: -$margin;
            }
            .owl-next {
                right: -$margin;
            }
        }
    }
}



.fl {
    float: left;
}

.fr {
    float: right;
}

.fn {
    float: none;
}

.clr {
    clear: both;
}

.tal {
    text-align: left;
}

.tar {
    text-align: right;
}

.tac {
    text-align: center;
}