@include header(122px, (
		$brLargeDesktop: 100px,
		$brDesktop: 80px,
		$brTablet: 92px
));

.the-header {
	@include transition(height .3s, background .3s);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	text-align: right;
	background: $headerBg;

	&.landing,
	&.is-404 {
		background-color: transparent;
		.wrap {
			@include outer-container($max-width);
		}
		
		.phone {
			@include align-center-v(2);
			right: 0;
		}
		
		@include media($brLargeDesktop) {
			.wrap {
				margin-left: $margin;
				margin-right: $margin;
			}
		}
		
		@include media($brMobile) {
			.phone {
				font-size: rem(14);
			}
		}
	}
	
	&:hover {
	}
	
	ul, li {
		margin: 0;
		padding: 0;
	}
	.wrap {
		position: relative;
		height: 100%;
		max-width: none;
		margin: 0 $margin;
	}
	
	#logo {
		@include align-center-v(2);
		display: block;
		
		img {
			@include transition(height .3s);
			display: block;
			height: 60px;
		}
	}
	
	.menu-toggle {
		@include align-center-v(2);
		right: 0;
		display: none;
		
		i {
			font-size: 42px;
			line-height: 1;
		}
	}
	
	.nav-header {
		height: 100%;
		text-align: right;
		
		.menu,
		.social-networks {
			display: inline-block;
			vertical-align: middle;
		}
		
		.menu {
			height: 100%;
			font-size: 0;
			
			> ul {
				@include clearfix;
				height: 100%;
				
				> li {
					position: relative;
					display: inline-block;
					height: 100%;
					font-size: 17px;
					vertical-align: top;
					
					&.menu-item-has-children:hover {
						&:after {
							height: 8px;
						}
					}
					
					&:after {
						@include align-center-h(2);
						@include transition(height .3s);
						@include filter(drop-shadow(0 1px 1px rgba(#000, .1)));
						top: 100%;
						display: block;
						content: '';
						width: 19px;
						height: 0;
						pointer-events: none;
						opacity: .95;
						background: url("#{$imgDir}/menu-marker.svg");
					}
					
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						> ul {
							@include transform(none);
							left: auto;
							right: 0;
						}
					}
					
					&.menu-item-has-children:hover,
					&.active {
					
					}
					
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current_page_item,
					&.current_page_ancestor,
					&.current_page_parent {
						> a {
							color: nth($color, 1);
						}
					}
					
					&:hover {
						> a {
							color: nth($color, 1);
						}
					}
					
					&.hilite {
						margin-left: 1em;
						> a {
							color: $mainBg;
							background: nth($color, 1);
						}
					}
					
					> a {
						position: relative;
						top: 50%;
						display: block;
						margin-top: -1em;
						padding: .5em 20px;
						font-family: $heading-font-family;
						font-weight: 700;
						line-height: 1;
						text-transform: uppercase;
						color: nth($color, 7);
						
						&:hover {
						}
					}
					
					a {
						&:hover {
							text-decoration: none;
						}
					}
					> ul {
						@include align-center-h(101);
						@include clearfix;
						padding: 20px 0 0;
						min-width: 250px;
						text-align: left;
						line-height: 1;
						font-size: 15px;
						text-transform: none;
						
						li {
							display: block;
							background: $mainBg;
							box-shadow: 0 2px 4px rgba(#000, 0.25);
							&.current-menu-item {
								a {
									font-weight: 700;
									color: nth($color, 1);
								}
							}
							&:last-child {
								a {
									border-bottom: 0;
								}
							}
							a {
								display: block;
								padding: 10px 20px;
								text-decoration: none;
								color: nth($color, 1);
								&:hover {
									color: $mainBg;
									background: nth($color, 1);
								}
							}
						}
					}
				}
				
				ul {
					display: none;
				}
			}
			
			a {
				color: nth($color, 4);
			}
		}
		
		a.phone-number {
			display: inline-block;
			height: 34px;
			padding: 0.5em 20px;
			margin-left: 1rem;
			background: nth($color, 3);
			color: #fff;
			font-size: 17px;
			font-weight: bold;
			line-height: 1;
			
			i {
				margin-right: 0.5rem;
				display: none;
				@include media($brTablet) {
					margin-right: 0;
					display: block;
				}
			}
			span {
				@include media($brTablet) {
					display: none;
				}
			}
			&:hover {
				background: nth($color, 4);
			}
			@include media($brTablet) {
				@include position(absolute, 50% 3rem null null);
				transform: translateY(-50%);
				height: auto;
				padding: 0.5rem 1rem;
				font-size: 16px;
			}
		}
		
		.social-networks {
			margin-left: 30px;
			
			a {
				&:hover {
					i {
						color: nth($color, 1);
						opacity: 1;
					}
				}
			}
			
			i {
				color: nth($color, 8);
				font-size: 20px;
				opacity: .5;
			}
		}
		
	}
	
	.search-toggle {
		position: relative;
		margin-left: 20px;
		
		i {
			line-height: 1;
			color: nth($color, 2);
		}
		
		&:hover {
			i {
				color: nth($color, 3);
			}
		}
	}

	#growl {
		@include transition(opacity .3s);
		position: absolute;
		z-index: 100;
		right: 40px;
		top: 110%;
		display: none;
		
		.content {
			padding: 1em;
			background: #fff;
			border: 2px solid nth($color, 4);
			box-shadow: 0 0 15px rgba(#000, .5);
			.product {
				font-weight: 700;
			}
		}
	}
	
	@media screen and (min-width: 1081px) {
		.nav-header {
			a.phone-number {
				position: relative;
				top: 2px;
			}
		}
	}
	
	@include media($brLargeDesktop) {
		
		#logo {
			img {
				height: 80px;
			}
		}
		.nav-header {
			.main-menu {
				> li {
					
					> a {
					}
				}
			}
			
		}
	}
	
	@include media($brDesktop) {
		
		.wrap {
		}
		
		#logo {
			img {
				height: 54px;
			}
		}
		#growl {
			right: 20px;
		}
		
		.nav-header {
			.menu {
				> ul > li,
				> li {
					margin-left: 20px;
					font-size: 12px;
					&.menu-item-has-children {
						> a:after {
						}
					}
					> a {
						padding: 0.5em 1em;
					}
					
					ul {
					}
				}
			}
			
			a.phone-number {
				height: auto;
				padding: 0.5em 1em;
				font-size: 12px;
			}
			
			.social-networks {
				display: none;
			}
			
		}
	}
	
	@include media($brTablet) {
		> .wrap {
		}
		
		#logo {
			img {
				height: 54px;
			}
		}
		
		#growl {
			left: 20px;
		}
		
		.menu-toggle {
			display: block;
		}
		
		.search-toggle {
			@include align-center-v(2);
			right: 50px;
			padding-right: 20px;
			border-right: 1px solid nth($color, 10);
			i {
				font-size: 24px;
			}
		}
		
		.nav-header {
			.menu {
				display: none;
			}
			a.phone-number {
				font-size: 16px;
			}
		}
		
	}
	
	@include media($brMobile) {
	}
}

body.scrolled {
	@include header(55px, (
			$brLargeDesktop: 55px,
			$brDesktop: 55px,
			$brTablet: 55px,
			$brMobile: 55px
	));
	
	.the-header {
		border-bottom: 1px solid nth($color, 10);
		&.landing,
		&.is-404 {
			background: $mainBg;
		}
		
		#logo {
			img {
				height: 60px;
			}
		}
		
	}
	
	@include media($brLargeDesktop) {
	
	}
	
	@include media($brDesktop) {
		.the-header {
			#logo {
				img {
				}
			}
		}
	}
	
	@include media($brTablet) {
		.the-header {
			#logo {
				img {
				}
			}
		}
	}
}
body:not(.scrolled) {
	.the-header.home {
		background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.7));
		.nav-header {
			.menu {
				> ul {
					> li {
						&.current-menu-item,
						&.current-menu-ancestor,
						&.current_page_item,
						&.current_page_ancestor,
						&.current_page_parent {
							> a {
								// color: #fff;
							}
						}
						
						&:hover {
							> a {
								color: nth($color, 1);
							}
						}
						
						> a {
							// color: #f0f0f0;
							&:hover {
							}
						}
						
						a {
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}
	}	
}